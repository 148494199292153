.node-type-media {
  .pane-node-field-media-type {
    .taxonomy-term-reference-0 {
      text-transform: uppercase;
      font-size: 14px;
      color: $wcm-med-gray;
      letter-spacing: 2px;
      font-weight: bold;
      padding: 10px 0;
    }
  }
}
