@mixin wcm_external_icon() {
  display: inline-block;
  content: '' !important;
  background-image: url('/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/external-link.svg');
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  margin-left: 6px;
  position: relative;
  top: 2px;
  @include breakpoint($md) {
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
  }
}

/* =Hero Callout
----------------------------------------------------------*/
.hero-callout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;
  background-position: center;
  height: 342px;
  color: $wcm-white;

  .container {
    @include breakpoint($xs) {
      padding: 0;
    }
  }
}

/* =Bar Callouts
----------------------------------------------------------*/
.bar-callout .row.is-flex {
  @include breakpoint($xs) {
    display: block;
  }
}

.bar-callout {
  @include breakpoint($md) {
    .container {
      padding-right: 0;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }
    .row {
      min-height: 315px;
      img {
        min-height: 315px;
      }
    }
  }
}

.bar-panel--title {
  a {
    &:after {
      content: "\e80d";
      color: $wcm-white;
    }
  }
  @include breakpoint($md) {
    a {
      &:after {
        line-height: 2;
      }
    }
  }
  @include breakpoint($sm) {
    a {
      &:after {
        line-height: 1.6;
      }
    }
  }
}

.bar-panel--title--linked {
  cursor: pointer;
}

.panel-pane {
  &:nth-child(odd) {
    .bar-panel--title {
      background-color: $wcm-red;
    }
  }

  &:nth-child(even) {
    .bar-panel--title {
      background-color: $wcm-dark-orange;
    }
  }
}

.bar-callout .row {
  &:hover {
    .bar-callout__image img {
      transform: scale(1.1);
    }
  }
}

.bar-callout__text {
  @include breakpoint($sm) {
    text-align: left;
    @include center();
    margin: 0 0 20px 0;
    font-size: 13px;
    width: 55%;
  }
  @include breakpoint($md) {
    font-size: 15px
  }
  a {
    .external-link {
      &:after {
        @include wcm-external-icon();
      }
    }

    &:after {
      color: $wcm-white;
      line-height: 1.6;
    }
  }

  @include breakpoint($xs) {
    text-align: left;
    max-height: 180px;
    margin: 20px 20px;

    h4 a {
      color: $wcm-white;
    }
  }
}

.bar-callout__title {
  font-size: 22px;
  @include breakpoint($xs) {
    margin: 0;
    font-size: 18px;
  }
  @include breakpoint($md) {
    margin-top: 0;
    padding-bottom: 5px;
  }
  a {
    color: $wcm-white;
    &:hover {
      text-decoration: underline;
    }
  }
}

.bar-callout__description {
  color: $wcm-white;
  margin: 5px 0 0;
  @include breakpoint($md) {
    font-size: 14px;
    line-height: 1.8;
  }
  &:hover {
    text-decoration: underline;
  }
}

.bar-callout__image {
  overflow: hidden;
  a {
    display: block;
    @include breakpoint($sm) {
      border-bottom: none;
    }
  }
}

.bar-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
  padding: 0;
  &:hover {
    transform: scale(1.1);
  }
}
