/**
 * Styles related to the various components on OFA
 *
 */

/* =Header
----------------------------------------------------------*/

/*.brand__lockup img {
  max-width: 55%;
}

.title-bar {
  @include breakpoint($xs) {
    font-size: 24px;
    line-height: 1.4;
    padding: 5px 0;
  }
}*/

 /* =Primary Navigation
----------------------------------------------------------*/

#primary-nav > .menu {
  display: flex;
  justify-content: space-between;
}

#primary-nav .level-1 {
  width: auto;
  font-size: 13px;
  line-height: 1.3;
  @include breakpoint($md) {
    font-size: 14px;
  }
  @include breakpoint($lg) {
    width: auto;
    font-size: 16px;
  }
}

/* =Drawer Navigation
----------------------------------------------------------*/

#drawer-nav > .menu > .level-1 > .menu {
  @include breakpoint($sm) {
    display: flex;
    justify-content: space-between;
  }
}

#drawer-nav > .menu > .level-1 > .menu > .level-2 {
  @include breakpoint($sm) {
    width: auto;
  }
}

/* =Sidebar
----------------------------------------------------------*/

.contact-info {
  color: $wcm-med-gray;
}

/* =Slate Callout
----------------------------------------------------------*/

.wcm-callout {
  background: $wcm-bg-gray;
  margin: 40px 0;
  a {
    display: block;
    border-right: 10px solid $wcm-border-gray;
    padding: 30px;
    position: relative;
    &:hover {
      text-decoration: none;
      border-right: 10px solid $wcm-bright-orange;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      right: -1.8rem;
      top: 45%;
      width: 0;
      height: 0;
      border-top: 1rem solid transparent;
      border-right: 1rem solid transparent;
      border-bottom: 1rem solid transparent;
      border-left: 1rem solid $wcm-bg-gray;
    }
    &.external-link:after {
      content: '';
      display: none;
    }
  }
}

.wcm-callout__no-link {
  border-right: 10px solid $wcm-border-gray;
  padding: 30px;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: -1.8rem;
    top: 45%;
    width: 0;
    height: 0;
    border-top: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-left: 1rem solid $wcm-bg-gray;
  }
}

.wcm-callout__headline {
  color: $wcm-red;
  font-size: 24px;
  font-family: $wcm-bold;
  margin: 0 0 10px;
}

.wcm-callout__text {
  color: $wcm-dark-gray;
}

/* =Image Callout
----------------------------------------------------------*/

.pane-bundle-wcm-image-callout {
  margin: 0 0 40px;
}

.wcm-image-callout__headline {
  font-size: 24px;
  font-family: $wcm-bold;
  line-height: 1.3;
  a {
    color: $wcm-red;
    &:hover {
      color: $wcm-dark-orange;
    }
  }
  margin: 0 0 10px;
}

.wcm-image-callout__image {
  margin: 0 0 20px;
}

/* =Auth Callouts
----------------------------------------------------------*/

.auth-callout {
  background: $wcm-bg-gray;
  margin: 0 0 20px;
  padding: 20px;
  text-align: center;
  border: 1px solid $wcm-border-gray;
  font-weight: 700;
}

.auth-callout__icon {
  &:before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/protected.svg');
    background-repeat: no-repeat;
    background-position: center center;
    padding: 30px;
    margin: 0 auto;
  }
}

.auth a {
  &:after {
    content: '';
    border-right: 1px solid #dddddd;
    padding: 0 0 0 25px;
  }
}

/* =News Teasers
----------------------------------------------------------*/

.view-news {
  .wcm-image-teaser {
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid $wcm-border-gray;
  }
  .views-row-last .wcm-image-teaser {
    border: none;
  }
  .teaser__image {
    @include clearfix();
  }
  .teaser-title {
    font-size: 16px;
    margin: 0;
    a {
      color: $wcm-dark-orange;
    }
  }
  .post-date {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: $wcm-med-gray;
    margin: 10px 0;
    letter-spacing: 1px;
  }
}

/* =FAQs
----------------------------------------------------------*/

.pane-faq-related-faqs {
  h3 {
    font-size: 24px;
    margin: 0 0 10px;
  }
  .btn--wcm {
    width: 180px;
    margin: 30px 0;
  }
}

.view-faq {
  .view-filters .form-select {
    @include breakpoint($sm) {
      min-width: 350px;
    }
  }
  .view-content h3 {
    margin-bottom: 20px;
  }
}

/* =Staff Directory
----------------------------------------------------------*/

.headshot-entry {
  margin-bottom: 20px;
}

/* =Handbook
----------------------------------------------------------*/

.handbook-section__title {
  font-size: 16px;
  color: $wcm-red;
}

.handbook-section__download  {
  display: inline-block;
  border-bottom: 1px solid $wcm-border-gray;
  padding: 0 0 5px;
  margin: 0 0 10px;
  @include clearfix();
  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    float: left;
    margin: 5px 10px 0 0;
    background-repeat: no-repeat;
    background-image: url(../images/cta_icons/sprite/sprite.svg);
    // The original background is 1264px 304px, each icon is 64px x 64px...so we scale it by .25 (16/64)
    background-size: 316px 76px;
    background-position: -40px -20px;
  }
}

.handbook-section__links {
  display: none;
  @include breakpoint($sm) {
    display: block;
  }
}

/* Safari doesn't handle the named anchors on the section PDFs...so we hide them for that browser only */
/* https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome
/* Safari 10.1+ (alternate method) */

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

    .handbook-section__links {

        display: none;

    }
}}

.handbook-section__links .item-list {
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    margin: 0 0 10px;
    padding: 0;
    list-style-type: none;
    &:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    float: left;
    margin: 5px 10px 0 0;
    background-repeat: no-repeat;
    background-image: url(../images/cta_icons/sprite/sprite.svg);
    // The original background is 1264px 304px, each icon is 64px x 64px...so we scale it by .25 (16/64)
    background-size: 316px 76px;
    background-position: -20px -60px;
  }
  }
}

/* =Guidebook Callout
----------------------------------------------------------*/

.ofa-guidebook-callout {
  margin: 0 0 20px;
  .btn--wcm {
    margin: 0 0 10px;
  }
  .ofa-guidebook-callout__caption {
    font-size: 13px;
    font-style: italic;
  }
}

/* =Twin Callout
----------------------------------------------------------*/

.ofa-twin-callout {
  margin: 0 0 30px;
  @include clearfix();
  h3 {
    @include breakpoint($md) {
      width: 50%;
      float: left;
      text-align: center;
      padding: 30px 0;
    }
  }
  h3:first-child {
    border-right: 1px solid $wcm-border-gray;
  }
}

/* =Login Notice
----------------------------------------------------------*/

.ofa-login-callout {
  margin: 40px 0;
  padding: 30px;
  border: 1px solid #dddddd;
}

.ofa-login-callout__description {
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: #777777;
  text-align: center;
  margin: 0 auto 30px;
}

.ofa-login-callout__action {
  text-align: center;
  .btn--wcm {
    font-size: 16px;
    width: auto;
    display: inline-block;
    padding: 6px 42px;
  }
}



/* =Footer
----------------------------------------------------------*/
.footer-address__address__email {
  a {
    word-break: break-word;
    border-bottom: 1px dotted;
  }
}

.footer-nav {
  @include breakpoint($md) {
    margin: 0 0 20px;
  }
  li {
    line-height: 1.4;
    padding: 0 0 8px;
  }
}

.footer-nav .level-1 {
  @include breakpoint($md) {
    width: (100% / 6);
    float: left;
    padding: 0 10px 5px;
  }
}

/* =Misc
----------------------------------------------------------*/

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.row.centered {
  @include breakpoint($md) {
    display: flex;
    align-items: center;
  }
}

.btn--minor {
  font-size: 13px;
  font-weight: 400;
  border-radius: 10px;
}

.fancybox-close {
  border-bottom: none;
  &:hover {
    border-bottom: none;
  }
}

/* =Page Specific One-offs
----------------------------------------------------------*/

.guidebook-instruction h4,
.handbook-instruction h4 {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  //color: #777;
  margin: 0 0 10px;
  letter-spacing: 2px;
}

/* =Academic Handbook
----------------------------------------------------------*/
.academic-handbook-prevnext {
  text-align: center;
  border-bottom: 1px solid $wcm-border-gray;
  padding-bottom: 20px;
  margin-bottom: 20px;

  h4 {
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 15px;
    letter-spacing: 1px;
  }

  .btn--small {
    padding: 10px 20px;
    position: relative;
    display: inline-block;
    font-weight: bold;

    width: 110px;

    @include breakpoint($md-only) {
      padding: 10px 17px;
      width: auto;
    }

    &.btn-prev {
      margin-right: 5px;
      span {
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-right: 7px solid $wcm-white;
        float: left;
        margin-right: 10px;
        margin-top: 2px;
      }

      &.btn-disabled {
        background-color: $wcm-border-gray;
        border-color: $wcm-border-gray;
        cursor: default;
        color: $wcm-black;

        span {
          border-right: 7px solid $wcm-black;
        }
      }
    }
    &.btn-next {
      margin-left: 5px;
      span {
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid $wcm-white;
        float: right;
        margin-left: 10px;
        margin-top: 2px;
      }

      &.btn-disabled {
        background-color: $wcm-border-gray;
        border-color: $wcm-border-gray;
        cursor: default;
        color: $wcm-black;

        span {
          border-left: 7px solid $wcm-black;
        }
      }
    }

  }

  .btn-return {
    margin-top: 10px;
    font-size: 14px;
  }
}

.academic-handbook-menu {
  margin: 20px 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      border-bottom: 1px solid $wcm-border-gray;
      display: block;
      padding: 12px 30px 12px 12px;
      position: relative;

      &:after {
        content: '\e80d';
        color: $wcm-dark-orange;
        position: absolute;
        right: 12px;
        top: 18px;
        @include fontello();
      }

      &:hover {
        @include box-shadow(0 0 6px 2px $wcm-border-gray);
        background: $wcm-white;
      }

    }
  }
}

.pane-node-field-handbook-date-updated {
  font-family: $wcm-italic;
  font-size: 20px;
  color: $wcm-med-gray;

  .field-label {
    display: inline;
  }

  &:after {
    content: '';
    position: relative;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 20px;
    width: 50px;
    border-bottom: 1px solid $wcm-border-gray;
  }
}

.node-type-academic-handbook {
  .pane-node-title {
    a {
      &.see-footnote {
        font-size: 25px;
        font-family: $font-family-sans-serif;
        font-weight: normal;
      }
    }
  }
  .main-content {
    h2, h3, h4, h5, h6 {
      a {
        &.see-footnote {
          font-size: 15px;
          font-family: $font-family-sans-serif;
          font-weight: normal;
        }
      }
    }
  }
}
