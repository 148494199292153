/**
 * Old Styles for the Home Page
 *
 */

 /* =Video Callout
 ----------------------------------------------------------*/

 .ofa-video {
  position: relative;
  background: none;
}

.ofa-video img,
.ofa-video .ofa-video__play {
  cursor: pointer;
}

.ofa-video .ofa-video__play,
.ofa-video .ofa-video__play:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d( -50%, -50%, 0 );
}

.ofa-video .ofa-video__play {
  width: 90px;
  height: 90px;
  background-color: transparent;
  z-index: 1;
  opacity: 0.8;
  border: 5px solid $wcm-white;
  border-radius: 100%;
}

.ofa-video .ofa-video__play:before {
  content: "";
  border-style: solid;
  border-width: 15px 0 15px 26.0px;
  border-color: transparent transparent transparent #fff;
}

/* =OFA Mission
----------------------------------------------------------*/

.ofa-mission {
  font-size: 18px;
  font-weight: 400;
  padding: 50px 0;
  text-align: center;
  @include breakpoint($sm) {
    font-size: 22px;
  }
}

.ofa-mission__text {
  padding: 0 30px;
}

/* =Statistics
----------------------------------------------------------*/

.ofa_stat_panel__window {
  position: relative;
}

.statistic__number {
  position: absolute;
  bottom: 0;
  left: 20px;
  color: $wcm-yellow;
  font-size: 60px;
  line-height: 1.0;
  font-family: $wcm-regular;
  z-index: 100;
  /* @include breakpoint($md) {
    font-size: 86px;
    left: 40px;
  } */
}

.statistic {
  background: $wcm-red;
  .statistic__image {
    background: $wcm-red;
    border-top: 30px solid $wcm-red;
    img {
      width: 100%;
      mix-blend-mode: multiply;
    }
    img.opacity-mode {
      opacity: 0.4;
    }
  }
}

.statistic__title {
  color: $wcm-white;
  font-size: 16px;
  line-height: 1.3;
  padding: 20px 30px;
  /* @include breakpoint($md) {
    font-size: 30px;
  } */
}

.ofa_stat_panel__window:hover {
  .statistic__number {
    color: $wcm-white;
  }
}

.statistic:nth-child(2) {
  background: $wcm-dark-orange;
  .statistic__image {
    background: $wcm-dark-orange;
    border-top: 30px solid $wcm-dark-orange;
  }
}

.statistic:nth-child(3) {
  background: $wcm-bright-orange;
  .statistic__image {
    background: $wcm-bright-orange;
    border-top: 30px solid $wcm-bright-orange;
  }
}

/* =Bar Link
----------------------------------------------------------*/

.bar-link a {
  display: block;
  background: $wcm-dark-orange;
  color: $wcm-white;
  margin: 5px 0 20px;
  padding: 15px 0;
  text-align: center;
  &:hover {
    background: $wcm-bright-orange;
  }
  &:after {
    content: '\e80d';
    padding-left: 8px;
    font-size: 60%;
    vertical-align: middle;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
  }
}

/* =Offering Callout
----------------------------------------------------------*/

.ofa-offering-callout {
  margin: 40px 0;
  @include breakpoint($sm) {
    margin: 80px 0;
  }
  h2 {
    font-size: 36px;
    font-family: $font-family-sans-serif;
    font-weight: 400;
    color: $wcm-med-gray;
    margin: 0 0 30px;
    @include breakpoint($sm) {
      font-size: 40px;
    }
  }
  p {
    margin: 0 0 30px;
  }
  .btn {
    margin: 0 0 30px;
    text-transform: capitalize;
  }
}

/* =Guide Callout
----------------------------------------------------------*/

.ofa-home-docs {
  margin: 40px 0;
  position: relative;
  text-align: center;
  @include breakpoint($md) {
    background: $wcm-yellow;
    text-align: left;
  }
  &:after {
    content: '';
    background: #ddd;
    height: 110px;
    width: 33%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    display: none;
    @include breakpoint($md) {
      display: block;
      height: 116px;
    }
  }
}

.ofa-home-docs.handbook-hover {
  @include breakpoint($md) {
    background: $wcm-bright-orange;
  }
}

.ofa-home-docs.guide-hover:after {
  @include breakpoint($md) {
    background: $wcm-bright-orange;
  }
}

.ofa-home-docs__guide {
  a {
    display: block;
    font-size: 30px;
    line-height: 1.0;
    font-family: $wcm-bold;
    background: #ddd;
    color: $wcm-white;
    padding: 45px 0 35px 0;
    &:hover {
      text-decoration: none;
    }
    @include breakpoint($md) {
      font-size: 36px;
    }
  }
}

.ofa-home-docs__guide--guidebook {
  a {
    padding: 40px 0 31px 35px;
    position: relative;
    z-index: 100;
    background-color: #ddd;
    &:hover {
      background: $wcm-bright-orange;
    }
  }
  .cta-icon {
    fill: white;
    color: white;
  }
}

.ofa-home-docs__guide--handbook {
  a {
    background: $wcm-yellow;
    &:hover {
      background: $wcm-bright-orange;
      &:before {
        @include breakpoint($sm) {
          left: 80%;
          opacity: 1;
        }
        @include breakpoint($lg) {
          left: 68%;
        }
      }
    }
    &:before {
      position: absolute;
      height: 100%;
      font-size: 125%;
      color: #fff;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
      content: '\e802';
      @include fontello();
      line-height: 2.0;
      left: 77%;
      opacity: 0;
      top: 15%;
      @include breakpoint($lg) {
        left: 63%;
      }
    }
  }
}

/* =Featured News
----------------------------------------------------------*/

.view-display-id-featured_news {
  width: 90%;
  margin: 0 auto;
  @include breakpoint($md) {
    width: auto;
    margin: 0;
  }
  .teaser__image {
    margin: 0 0 20px;
    @include breakpoint($md) {
      width: 60%;
      max-width: 800px;
      float: left;
      margin: 0;
    }
  }
  .teaser__detail {
    @include breakpoint($md) {
      width: 40%;
      float: left;
      padding: 0 30px 0;
    }
    @include breakpoint($lg) {
      padding: 60px 30px 0;
    }
  }
  h2 {
    font-size: 36px;
    font-family: $font-family-sans-serif;
    font-weight: 400;
    color: $wcm-med-gray;
    margin: 0 0 20px;
    @include breakpoint($sm) {
      margin: 0 0 30px;
      font-size: 40px;
    }
  }
  .featured-news__title {
    font-size: 20px;
    margin: 0 0 10px;
  }
  .featured-news__date {
    color: $wcm-med-gray;
    text-transform: uppercase;
    font-size: 13px;
    font-family: $wcm-bold;
    letter-spacing: 2px;
  }
  .featured-news__read-all {
    margin: 40px 0;
    text-transform: capitalize;
  }
}

/* =Headline Callout
----------------------------------------------------------*/

.headline-callout {
  max-width: 960px;
  margin: 0 auto;
  border: 1px solid $wcm-border-gray;
  padding: 30px 30px 20px;
  text-align: center;
  @include breakpoint($sm) {
    width: 80%;
  }
}

.headline-callout .col-sm-6:first-of-type {
  border-right: 1px solid $wcm-border-gray;
}

.headline-callout__title {
  font-size: 18px;
  margin: 0 0 10px;
}

/* =Misc
----------------------------------------------------------*/

.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 10;
}

/**
 * New Styles for the Home page
 */

.front {
  background-color: $wcm-white;
}

.ofa-home {
  position: relative;

  #hero {
    margin-bottom: 25px;
  }

  #welcome-message-container {
    @include breakpoint($xs) {
      text-align: center;
    }

    #welcome-image {
      @include breakpoint($xs) {
        margin-bottom: 10px;
      }
      img {
        max-width: inherit;
      }
    }

    #welcome-text {
      font-size: 15px;
      line-height: 28px;

      a {
        font-size: 13px;
      }
    }
  }

  #events {
    @include breakpoint($sm) {
      margin-top: 25px;
    }
    @include breakpoint($md) {
      margin-bottom: -250px;
    }

    #events-container {
      @include breakpoint($md) {
        position: relative;
        top: -250px;
      }

      background-color: $wcm-white;
      box-shadow: 1px 1px 2px 1px $wcm-border-gray;
      padding: 0;

      h2 {
        text-align: center;
        font-size: 22px;
        background-color: $wcm-dark-orange;
        margin: 0 0 20px 0;
        padding: 15px 0;

        a {
          color: $wcm-white;

          &:after {
            color: $wcm-white;
          }
        }
      }

      .wcm-upcoming-events {
        padding: 0 20px 0 40px;
        @include breakpoint($xs) {
          padding: 0 20px 0 20px;
        }

        h4 {
          margin-bottom: 20px;
        }

        .container {
          margin-right: -15px;
        }

        .wcm-upcoming-event {
          padding: 10px 5px 10px 0;
          margin-bottom: 25px;

          &:last-child {
            margin-bottom: 55px;
          }

          .wcm-upcoming-event__icon {
            width: 53px;
            height: 53px;
            left: -26px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

            svg {
              width: 26px;
              height: 26px;
            }
          }

          .wcm-upcoming-event-info-container {
            margin: 0 0 0 30px;
          }

          .wcm-upcoming-event__date___month {
            color: $wcm-black;
            font-size: 18px;
            line-height: 24px;
          }
          .wcm-upcoming-event__date___day {
            color: $wcm-black;
            font-size: 22px;
            line-height: 30px;
          }
          .event__info {
            margin: 0;
            font-size: 11px;
            .event__time {
              color: #707070;
              font-size: 11px;
            }
            .event__location {
              display: none;
            }
          }
          .event__title {
            font-size: 13px;
            line-height: 18px;
            margin: 0;
          }
        }
      }
    }
  }

  #news {
    margin-top: 40px;

    .latest-news-updates {
      font-size: 22px;
    }

    .latest-news-row {
      line-height: 20px;

      .latest-news__date {
        p {
          margin: 0 0 5px;
        }
      }
    }

    @include breakpoint($md) {
      .latest-news-row {
        padding-right: 75px;
      }
    }
  }

  #quick-links-container {
    margin-top: 30px;

    #quick-links {
      min-height: 200px;
      background-color: $wcm-dark-orange;
      color: $wcm-white;
      border-color: $wcm-white;
      text-align: center;
      padding: 30px 0;

      .container {
        max-width: 1000px;
      }

      a {
        @include breakpoint($xs) {
          width: 100%;
          margin-top: 10px;
        }

        transition: all 0.3s ease 0s;
        display: block;
        color: $wcm-white;
        width: 130px;
        height: 140px;
        border: 2px solid;
        padding: 20px 25px;
        margin: 0 auto;
        box-shadow: 0 2px 10px -5px $wcm-black;

        .cta-icon {
          width: 48px;
          height: 48px;
        }

        &:hover,
        &:focus {
          transform: scale(1.1);
        }

        svg {
          color: $wcm-white;
          fill: $wcm-white;
        }
        span {
          @include breakpoint($xs) {
            display: block;
            width: 100%;
          }
        }
      }
    }

    .icon-pdf a,
    .icon-units a {
      padding-top: 25px !important;
    }
  }

  #bar-callouts {
    margin-top: 30px;
  }
}
