/**
 * Styles related to the Forms and Templates section
 *
 */

 /* =Forms
----------------------------------------------------------*/

.view-forms {
  .views-exposed-form select {
    min-width: 340px;
    max-width: none;
  }
  h3 {
    margin-bottom: 20px;
  }
}

.ofa-form {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid $wcm-border-gray;
}

.ofa-form:first-child {
  margin-top: 40px;
}

.ofa-form__title {
  font-size: 18px;
  font-weight: 700;
  a {
    border: none;
  }
  a:before {
    display: inline-block;
    content: '';
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
    margin-left: 6px;
    position: relative;
    top: 2px;
    margin-right: 13px;
  }
}

.ofa-form__title--download {
  a:before {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/pdf.svg');
  }
}

.ofa-form__title--external {
  a:before {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
  }
}

.ofa-form__description {
  padding-left: 40px;
}

.ofa-form__category {
  font-size: 13px;
  text-transform: uppercase;
  color: $wcm-med-gray;
  padding-left: 40px;
}

.ofa-form .views-field-field-offering-category {
  @include breakpoint($sm) {
    margin-left: 40px;
  }
}

/* =Weight Sorting
----------------------------------------------------------*/

.view-weight table {
  width: 80%;
  margin: 0 auto;
}

.tabledrag-handle {
  border: none;
}

