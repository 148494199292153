/** Media List **/
.view-display-id-media_list {
  .views-exposed-form .views-exposed-widget {
    margin-bottom: 30px;

    .form-item-field-media-type-tid .select2-container {
      min-width: 115px !important;
      max-width: 115px !important;
    }
    .form-item-field-media-type-tid .select2-container .selection .select2-selection {
      height: 50px !important;
      outline: 0;
      border: 1px solid $wcm-border-gray;
      line-height: 30px;

      &:focus {
        outline: $wcm-dark-orange auto 5px;
      }
    }
    .form-item-sort-bef-combine .select2-container {
      min-width: 170px !important;
      max-width: 200px !important;
    }
    .form-item-combine input {
      height: 50px;
      display: block;
    }
    .form-item-sort-bef-combine label {
      display: inline-block;
      width: 100%;
      font-size: 15px;
    }
    .form-submit {
      margin: 2.3em 0;
    }
  }

  .media-row {
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid $wcm-border-gray;
    &:last-child {
      border: none;
    }

    .wcm-file-download {
      svg {
        color: $wcm-dark-orange;
        fill: $wcm-bright-orange;
      }
    }

    .media-row__title {
      font-size: 20px;
      padding: 0 0 10px;
      color: $wcm-red;
      font-family: $wcm-bold;
      a {
        color: $wcm-red;
      }

      a {
        color: $wcm-red;
        &:after {
          content: '\e80d';
          color: $wcm-bright-orange;
          padding-left: 10px;
          font-size: 60%;
          vertical-align: top;
          @include fontello();
          text-decoration: none;
          line-height: 2;
        }
      }
    }

    .media-row__media_type {
      text-transform: uppercase;
      color: $wcm-med-gray;
      font-family: $wcm-bold;
      font-size: 13px;
      letter-spacing: 2px;
      padding-bottom: 10px;
    }
  }
}

/** Home Page Latest News **/
.view-display-id-home_latest_news_updates {
  margin-top: 25px;

  .latest-news-row {}

  .latest-news__title {
    font-size: 15px;
    padding: 0 0 5px;
    font-family: $wcm-bold;
  }

  .latest-news__date {
    font-size: 15px;
  }
  .latest-news-teaser-text {
    font-size: 15px;
  }
}

/** Programs List **/
.view-display-id-programs_list {
  .views-exposed-form .views-exposed-widget {
    margin-bottom: 30px;

    .form-item-field-program-category-target-id select {
      max-width: 250px !important;
    }

    .form-item-sort-bef-combine .form-select {
      min-width: 150px !important;
      max-width: 175px !important;
    }

    .form-item input {
      height: 50px;
      display: block;
    }
    .form-item-sort-bef label {
      display: inline-block;
      width: 100%;
      font-size: 15px;
    }
    .form-item-combine input {
      height: 50px;
      display: block;
    }
    .form-item-sort-bef-combine label {
      display: inline-block;
      width: 100%;
      font-size: 15px;
    }
    .form-submit {
      margin: 2.3em 0;
    }
  }

  h3 {
    margin-bottom: 10px !important;
  }

  .programs-list-row {
    margin: 0 0 30px;
    padding: 0;
    &:last-child {
      border: none;
    }

    .programs-list-row__title {
      font-size: 16px;
    }
  }
}

/** Awards List **/
.view-display-id-awards_list {
  .views-exposed-widget {
    margin-bottom: 30px;

    .form-item-field-award-category-tid select {
      max-width: 250px !important;
    }

    .form-item-sort-bef-combine .form-select {
      max-width: 250px !important;
    }

    .form-item input {
      height: 50px;
      display: block;
    }
    .form-item-sort-bef label {
      display: inline-block;
      width: 100%;
      font-size: 15px;
    }
    .form-item-combine input {
      height: 50px;
      display: block;
    }
    .form-item-sort-bef-combine label {
      display: inline-block;
      width: 100%;
      font-size: 15px;
    }
    .form-submit {
      margin: 2.3em 0;
    }
  }

  h3 {
    margin-bottom: 10px !important;
  }

  .awards-list-row {
    margin: 0 0 30px;
    padding: 0;
    &:last-child {
      border: none;
    }

    .awards-list-row__title {
      font-size: 16px;
    }
  }
}

/** Resources List **/
.view-display-id-resources_list {
  .views-exposed-form {
    .views-widget-filter-combine {
      width: 100%;

      input {
        width: 45%;
      }
    }

    .views-exposed-widget {
      margin-bottom: 30px;

      .form-item-field-resource-category-tid select {
        width: 250px !important;
      }

      .form-item-form-type select {
        width: 250px !important;
      }

      .form-item-sort-bef-combine select {
        width: 250px !important;
      }

      .form-item input {
        height: 50px;
        display: block;
      }
      .form-item-sort-bef label {
        display: inline-block;
        width: 100%;
        font-size: 15px;
      }
      .form-item-combine input {
        height: 50px;
        display: block;
      }
      .form-item-sort-bef-combine label {
        display: inline-block;
        width: 100%;
        font-size: 15px;
      }
      .form-submit {
        margin: 2.3em 0;
      }
    }
  }

  .resources-list-row {
    margin: 0 0 20px;
    padding: 0;
    &:last-child {
      border: none;
    }

    .resources-list-row__title { }
  }
}
