/**
 * Styles related to the Offerings/Programs/Events
 *
 */

.program {
  position: relative;
  margin: 0 0 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid $wcm-border-gray;
  @include clearfix();
}

.ofa-featured-programs {
  border: 3px solid #ddd;
  .view-content {
    @include clearfix();
  }
}

.ofa-featured-programs__header {
  background: #f7f7f7;
  margin: 0 0 30px;
  padding: 20px 30px;
  h3 {
    margin: 0;
  }
}

.ofa-featured-programs__description {
  padding: 0 30px 20px;
}

.views-row-last {
  .program,
  .event {
    border-bottom: none;
    border-right: none;
  }
}

.event {
  margin: 0 10px 30px 0;
  padding: 0 0 30px;
  border-bottom: 1px solid $wcm-border-gray;
  @include clearfix();
}

.event-list--upcoming .event {
  margin: 0 30px 30px 0;
}

.offering__title {
  font-size: 16px;
  font-weight: 700;
  color: $wcm-dark-orange;
  a.external-link:after {
    display: none;
  }
 }

.offering__category {
  margin: 10px 0 0;
}

.event__category--row {
  margin: 0 0 10px;
}

.offering__category--icon {
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
    background-repeat: no-repeat;
    background-position: 15px 15px;
    border: 3px solid $wcm-dark-orange;
    border-radius: 100%;
    padding: 30px;
    margin: 0 auto;
  }
}

.program--featured {
  padding: 0 30px 0 0;
  border-bottom: none;
  text-align: center;
  &:before {
    display: none;
  }
  @include breakpoint($md) {
    border-right: 1px solid $wcm-border-gray;
  }
  .offering__title {
    margin: 10px 0 0px;
    text-align: center;
  }
}

.event--featured {
  text-align: left;
  border-bottom: none;
  margin: 0 0 20px;
  padding: 0;
  @include breakpoint($md) {
    margin: 0 10px 30px 0;
    padding: 0 0 30px;
  }
  .offering__category--icon {
    &:before {
      background-position: 0 0;
      border: none;
      border-radius: 0;
      padding: 0;
      margin: 0;
    }
  }
  .offering__title {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    margin: 5px 0;
  }
  .event__category {
    padding-left: 15px;
  }
  .event__description {
    font-size: 14px;
    a {
      word-wrap: break-word;
    }
  }
  .event__link {
    font-size: 14px;
  }

}

.program__link,
.event__link {
 margin: 10px 0;
}

.program__file a {
  background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/pdf.svg');
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 25px;
}

.event__date {
  color: $wcm-med-gray;
  @include breakpoint($sm) {
    text-align: center;
  }
}

.event__date--month {
  font-size: 20px;
  line-height: 1.0;
}

.event__date--day {
  font-size: 32px;
}

.event__date--full,
.event__time,
.event__location {
  font-size: 13px;
}

.event__date--full {
  margin-right: 10px;
  padding-right: 14px;
  border-right: 1px solid $wcm-border-gray;
}

.event__location {
  margin-left: 10px;
  padding-left: 14px;
  border-left: 1px solid $wcm-border-gray;
}

img.program-image {
  width: 160px;
  height: auto;
  margin: 10px 0;
  @include breakpoint($sm) {
    float: right;
    margin: 0;
  }
  @include breakpoint($md) {
    width: 135px;
  }
  @include breakpoint($lg) {
    width: 160px;
  }
}

.event img.program-image {
  @include breakpoint($md) {
    margin-right: 20px;
  }
}

// Variation of flex for Views row

.ofa-featured-events .view-content {
  display: flex;
  flex-wrap: wrap;
  &:before {
    display: block;
  }
}

.ofa-featured-events .view-content > [class*='col-'] {
  display: flex;
  flex-direction: column;
  @include breakpoint($md) {
    border-right: 1px solid $wcm-border-gray;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.ofa-featured-events .view-content  .views-row-first {
  padding-left: 20px;
}

.ofa-featured-events .view-content  .views-row-last {
  border-right: none;
  padding-right: 20px;
}

.offering__no-category--icon {
  @include breakpoint($sm) {
    height: 30px;
  }
}

/* =Program List w Filters
----------------------------------------------------------*/

.program-list,
.event-list {
  .checkbox {
    margin: 10px 0;
    padding: 10px 0 0;
    border: 1px solid $wcm-border-gray;
    border-width: 1px 0 0;
    label {
      font-size: 13px;
      font-weight: 700;
      color: $wcm-dark-gray;
    }
  }
  input[type="checkbox"] {
    margin-top: 7px;
  }
  button:focus {
    outline: none;
  }
}

.program-list__text-search,
.event-list__text-search {
  margin: 0 0 40px;
  .form-control {
    max-width: none;
    padding-right: 30px;
  }
  .form-control + .glyphicon {
    position: absolute;
    top: 32px;
    right: 10px;
    padding: 8px 27px;
    color: $wcm-med-gray;
    font-size: 20px;
  }
}

.offering-list__filters {
  position: relative;
  margin: 0 0 30px;
  @include breakpoint($md) {
    margin: 0;
    padding-right: 30px;
  }
  h3 {
    margin: 0;
    font-size: 18px;
  }
}

.offering-list__filters .checkbox {
  &:after {
    content: '';
    display: block;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
  }
}

#ofa-clear-filters {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 30px;
  border: none;
  background: none;
  font-size: 13px;
  margin: 0;
  padding: 0;
}

.program-list__results {
  @include breakpoint($md) {
    border: 3px solid $wcm-border-gray;
    padding: 30px;
    min-height: 400px;
  }
}

.event-list__results {
  @include breakpoint($md) {
    border: 3px solid $wcm-border-gray;
    min-height: 400px;
  }
}

.offering-list__no-results {
  padding: 30px;
  h4 {
    margin: 0;
  }
}

.event-list__view .view-empty {
  padding: 0 40px;
}

// Only show the list view of the calendar for mobile
.event-list__cta{
  @include breakpoint($xs) {
    display: none;
  }
}

.event-list__view {
  @include breakpoint($xs) {
    display: block !important;
  }
}

.event-calendar__view {
  @include breakpoint($xs) {
    display: none !important;
  }
}

.event-list__toggle-view {
  background: $wcm-bg-gray;
  margin: 0 0 30px;
  padding: 20px 30px;
  button {
    font-size: 14px;
    border: 1px solid $wcm-border-gray;
    border-radius: 20px;
    color: $wcm-med-gray;
    margin: 0 5px 0 0;
    padding: 5px 20px 5px 40px;
    background-color: $wcm-bg-gray;
    background-size: 18px 18px;
    background-position: 12px 7px;
    background-repeat: no-repeat;
  }
  button.active {
    color: $wcm-dark-orange;
    background-color: $wcm-white;
  }
  button.event-list-toggle {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/list-view.svg');
    @include breakpoint($xs) {
      color: $wcm-dark-orange !important;
      background-color: $wcm-white !important;
    }
  }
  button.event-calendar-toggle {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/calendar.svg');
    @include breakpoint($xs) {
      display: none;
    }
  }
}

.event-list__toggle-view h3 {
  margin: 10px 0 0;
}

.event-list__cta {
  @include breakpoint($sm) {
    text-align: right;
  }
}

.offering-view-more a {
  display: block;
  background: $wcm-dark-orange;
  color: $wcm-white;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 40px;
  padding: 10px 0;
  font-size: 13px;
  letter-spacing: 2px;
  &:hover {
    text-decoration: none;
    background: $wcm-bright-orange;
  }
  &:after {
    content: '\e80d';
    padding-left: 8px;
    font-size: 60%;
    vertical-align: middle;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
  }
}

/* =Calendar
----------------------------------------------------------*/

.view-events {
  .fullcalendar {
    padding: 0 10px 30px;
  }
}

.fc-header .fc-button {
  border: none;
  background: none;
  box-shadow: none;
  color: $wcm-bright-orange;
}

.fc-content {
  .calendar-event {
    line-height: 1.2;
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
      background-repeat: no-repeat;
    }
    a {
      color: $wcm-dark-orange;
      font-size: 11px;
    }
  }
  .offering-type--none {
    &:before {
      display: none;
    }
  }
}

#calendar tbody {
  border: none;
}

/* =Calendar Event Modal
----------------------------------------------------------*/

.fancybox-inner #ofa-event {
  margin: 0;
  padding: 20px 0;
  border-bottom: none;
  @include v-center();
}

.fancybox-inner {
  .event__date--month {
    padding-top: 5px;
  }
  .event__date--day {
    line-height: 1.2;
  }
  .event__info {
    padding-bottom: 5px;
  }
}

/* =Sidebar Calendar
----------------------------------------------------------*/

#ofa-sidebar-calendar {
  width: 100%;
  margin: 30px auto;
  font-size: 15px;
  .fc-header tbody {
    border: none;
  }
  .fc-header-title h2 {
    font-size: 15px;
    margin-top: 10px;
  }
  .fc-view-month .fc-event {
    font-size: 0;
    overflow: hidden;
    height: 2px;
  }
  .fc-widget-content {
    width: 44px;
    vertical-align: middle;
  }
  .fc-day {
    cursor: pointer;
  }
  .fc-day-content {
    height: 10px;
  }
  .fc-event {
    border: 1px solid $wcm-bright-orange;
    background-color: $wcm-bright-orange;
  }
  .fc-view-agendaWeek .fc-event-vert {
    font-size: 0;
    overflow: hidden;
    width: 2px !important;
  }
  .fc-agenda-axis {
    width: 20px !important;
    font-size: .7em;
  }

  .fc-button-content {
    padding: 0;
  }
}

/* =Sidebar Calendar Event List
----------------------------------------------------------*/

#ofa-sidebar-event-list {
  margin: 30px 0;
  h3 {
    font-size: 15px;
    margin: 0 0 5px;
  }
}

.ofa-sidebar-events {
  .fc-state-highlight {
    background: $wcm-bright-orange;
    color: $wcm-white;
  }
}

.ofa-sidebar-event {
  margin: 0 0 20px;
}

.ofa-sidebar-event__title {
  font-size: 14px;
  margin: 0 0 5px;
  line-height: 1.4;
  a {
    color: $wcm-dark-orange;
    &:hover {
      color: $wcm-bright-orange;
    }
  }
}

/* =Professional Development Offering Type List
----------------------------------------------------------*/

.ofa-development-types {
  @include breakpoint($md) {
    column-count: 2;
  }
  .offering-type {
    display: block;
    font-size: 15px;
  }
}

.offering-type {
  font-size: 12px;
  color: #777;
  display: inline-block;
  margin-right: 15px;
  &:before {
    content: '';
    display: inline-block;
    width: 25px;
    height: 20px;
    background-size: 20px 20px;
    background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg);
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    top: 12px;
    border: 2px solid $wcm-border-gray;
    border-radius: 100%;
    padding: 15px;
    margin-right: 5px;
  }
}

/* =Offering Icons
----------------------------------------------------------*/

.offering-type--6 {
  // Program and Event Teasers
  &:before {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/physician.svg');
  }
  // Checkbox Filters
  &:after {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/physician.svg');
  }
  .offering__category--icon:before {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/physician.svg');
  }
}

.offering-type--7 {
  &:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__diversity.svg');
  }
  &:after {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__diversity.svg');
  }
  .offering__category--icon:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__diversity.svg');
  }
}

.offering-type--8 {
  &:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__faculty-affairs.svg');
  }
  &:after {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__faculty-affairs.svg');
  }
  .offering__category--icon:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__faculty-affairs.svg');
  }
}

.offering-type--9 {
  &:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__leadership.svg');
  }
  &:after {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__leadership.svg');
  }
  .offering__category--icon:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__leadership.svg');
  }
}

.offering-type--10 {
  &:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__mentoring.svg');
  }
  &:after {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__mentoring.svg');
  }
  .offering__category--icon:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__mentoring.svg');
  }
}

.offering-type--11 {
  &:before {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/labs.svg');
  }
  &:after {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/labs.svg');
  }
  .offering__category--icon:before {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/labs.svg');
  }
}

.offering-type--12 {
  &:before {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/education.svg');
  }
  &:after {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/education.svg');
  }
  .offering__category--icon:before {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/education.svg');

  }
}

.offering-type--13 {
  &:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__technology.svg');
  }
  &:after {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__technology.svg');
  }
  .offering__category--icon:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__technology.svg');
  }
}

.offering-type--14 {
  &:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__wellness.svg');
  }
  &:after {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__wellness.svg');
  }
  .offering__category--icon:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__wellness.svg');
  }
}

.offering-type--30 {
  &:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__communication.svg');
  }
  &:after {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__communication.svg');
  }
  .offering__category--icon:before {
    background-image: url('/sites/all/themes/ofa/images/ofa_icons/ofa_icons__communication.svg');
  }
}


/* =New Programs Details (Panalizer Based)
----------------------------------------------------------*/
.node-type-program {
  img.program-image {
    width: auto;
    float: none;
  }

  .pane-node-field-program-feature {
    text-transform: uppercase;
    font-size: 14px;
    color: $wcm-med-gray;
    letter-spacing: 2px;
    font-weight: bold;
    padding: 10px 0;
    margin-bottom: 10px;
  }

  .pane-node-field-program-category,
  .pane-node-field-program-upload {
    margin: 0 0 20px 0;
  }
}
